.wrapper {
    font-size: 12px !important;

    span {
        font-size: 12px !important;
    }
    img {
        max-width: 20px !important;
        vertical-align: middle !important;
    }
}