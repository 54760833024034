@import '../../../styles/global.scss';


.wrapper {
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    // @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop) {
    //     margin: 0 20vw;
    // }

    // @media screen and (min-width: $breakpoint-large-mobile) and (max-width: $breakpoint-tablet) {
    //     margin: 0 10vw;
    // }

    // @media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-large-mobile) {
    //     margin: 0 5vw;
    // }

    .innerWrapper {
        margin: 0 auto;
        width: 900px;
        @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-large-computer) {
            width: 700px;
        }
    
        @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop) {
            width: 700px;
        }
    
        @media screen and (min-width: $breakpoint-large-mobile) and (max-width: $breakpoint-tablet) {
            width: 400px;
        }
    
        @media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-large-mobile) {
            width: 300px
        }
    

        hr {
            width: 100%;
            color: rgb(96, 96, 96);
        }

        h1:first-child {
            font-size: 66px;
            @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-large-computer) {
                font-size: 60px;
            }

            @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop) {
                font-size: 55px;
            }
        
            @media screen and (min-width: $breakpoint-large-mobile) and (max-width: $breakpoint-tablet) {
                font-size: 48px;
            }
        
            @media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-large-mobile) {
                font-size: 40px;
            }
        }
        
        h1 {
            margin-bottom: 3rem;
            font-family: $nangec-font !important;
            @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-large-computer) {
                font-size: 54px;
            }
        
            @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop) {
                font-size: 44px;
            }
        
            @media screen and (min-width: $breakpoint-large-mobile) and (max-width: $breakpoint-tablet) {
                font-size: 39px;
            }
        
            @media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-large-mobile) {
                font-size: 32px;
            }

            span {
                @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-large-computer) {
                    font-size: 54px;
                }
            
                @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop) {
                    font-size: 44px;
                }
            
                @media screen and (min-width: $breakpoint-large-mobile) and (max-width: $breakpoint-tablet) {
                    font-size: 39px;
                }
            
                @media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-large-mobile) {
                    font-size: 32px;
                }
            }
        }

        h2 {
            margin-bottom: 3rem;
            font-family: 'Roboto', sans-serif !important;

            span {
                @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-large-computer) {
                    font-size: 40px;
                }
            
                @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop) {
                    font-size: 30px;
                }
            
                @media screen and (min-width: $breakpoint-large-mobile) and (max-width: $breakpoint-tablet) {
                    font-size: 25px;
                }
            
                @media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-large-mobile) {
                    font-size: 22px;
                }
            }
            @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-large-computer) {
                font-size: 40px;
            }
        
            @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop) {
                font-size: 30px;
            }
        
            @media screen and (min-width: $breakpoint-large-mobile) and (max-width: $breakpoint-tablet) {
                font-size: 25px;
            }
        
            @media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-large-mobile) {
                font-size: 22px;
            }
        }

        img {
            margin-bottom: 4rem;
            max-width: 100%;
            object-fit: contain;
        }
    
        p {
            margin-bottom: 3rem;
            word-wrap:break-word;
            @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-large-computer) {
                font-size: 18px;
            }
        
            @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop) {
                font-size: 16px;
            }
        
            @media screen and (min-width: $breakpoint-large-mobile) and (max-width: $breakpoint-tablet) {
                font-size: 14.5px;
            }
        
            @media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-large-mobile) {
                font-size: 14px;
            }

            span {
                @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-large-computer) {
                    font-size: 20px;
                }
            
                @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop) {
                    font-size: 17px;
                }
            
                @media screen and (min-width: $breakpoint-large-mobile) and (max-width: $breakpoint-tablet) {
                    font-size: 14.5px;
                }
            
                @media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-large-mobile) {
                    font-size: 14px;
                }
            }
        }
    }
}