.wrapper {
    margin: 4rem;
    padding-bottom: 4rem;

    .placeholderWrapper {
        height: 235px;

        .placeholder {
            margin: 0 auto;
        }
    }

    .segment { 
        height: 200px;
        width: 200px;
        box-shadow: none !important;
        border: none !important;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-bottom: 2rem !important;
    }

    .thankyou {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Roboto', sans-serif !important;
    }
}

// guidelines samir cc:d ali, SMTP research office 365, ping email w/ nameservers