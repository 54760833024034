@import '../../../../styles/global.scss';

.wrapper {
    display: flex;
    align-items: center;
    background-color: lightgrey;
    height: 60px;
    cursor: pointer;
    @media screen and (max-width: $breakpoint-large-mobile) {
        position: relative;
    }

    .imgWrap {
        margin-left: 1rem;

        @media screen and (max-width: $breakpoint-large-mobile) {
            margin-left: 5%;
        }
        .img {
            max-width: 40px;
            vertical-align: bottom;
        }
    }
    

    .colorPrev {
        height: 40px;
        width: 40px;
        margin-left: 1rem;
    }

    .text {
        margin-left: 1rem;
        font-size: 12px;
        @media screen and (max-width: $breakpoint-large-mobile) {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            margin-left: 0;
        }
        @media screen and (min-width: $breakpoint-large-mobile + 1) {
            margin-right: 1rem;
        }
    }
}

/* for the selected metal type only */
.selectedWrapper {
    display: flex;
    align-items: center;
    background-color: lightgrey;
    height: 60px;
    outline: none;
    border: 1px solid blue;
    border-radius: 4px;
    cursor: pointer;
    @media screen and (max-width: $breakpoint-large-mobile) {
        text-align: center;
    }

    .imgWrap {
        margin-left: 1rem;
        .img {
            max-width: 40px;
            vertical-align: bottom;
        }
    }
    
    .colorPrev {
        height: 40px;
        width: 40px;
        margin-left: 1rem;
    }

    .text {
        margin-left: 1rem;
        font-size: 12px;
        @media screen and (max-width: $breakpoint-large-mobile) {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            margin-left: 0;
        }
        @media screen and (min-width: $breakpoint-large-mobile + 1) {
            margin-right: 1rem;
        }
    }
}

.wrapper, .selectedWrapper {
    .colorPrev {
        @media screen and (max-width: $breakpoint-large-mobile) {
            display: none;
        }
    }
}