.linkWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 70%;
    margin: 0 auto;

    a {
        margin: 7px 16px;
        color: black;
        text-decoration: underline;
    }
}

.adminWrapper {
    margin: 0 auto;
    width: 50%;
}