@import '../../../styles/global.scss';

.wrapper {
    margin: $pages-outer-margin;
    
    @media screen and (max-width: 680px) {
        margin: 1rem;
    }
    .cardsWrapper {
        display: grid;
        justify-items: center;
        row-gap: 2rem;
        grid-auto-rows: 1fr;

        @media screen and (min-width: $breakpoint-laptop) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    
        @media screen and (max-width: $breakpoint-laptop - 1) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    
        @media screen and (max-width: 970px) {
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr;
            justify-items: center;
        }
    }
}
