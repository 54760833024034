@import '../../../../styles/global.scss';
.wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 275px;
    background-color: #e8e8e8;
    border-radius: 15px;
    position: relative;
    height: 650px;


    p:first-child {
        img {
            height: 125px;
            max-width: 90%;
            margin: 1rem 0;
            border-radius: 10px;
            object-fit: none;
        }
    }

    

    img {
        object-fit: contain;
        max-width: 90%;
    }



    h1 {
        margin-bottom: 2rem;
        margin-left: $inner-card-spacing;
        margin-right: $inner-card-spacing;
        font-family: $nangec-font !important;
    }


    p {
        margin: 0 $inner-card-spacing;
        margin-bottom: 1rem;
        font-size: 14px;
        max-width: 90%;
        word-break: break-word;
        white-space: normal;
    }

    span {
        p {
            margin: 0 $inner-card-spacing;
            margin-bottom: 1rem;
            font-size: 14px;
            max-width: 90%;
        }
    }

    
    p:last-of-type {
        position: absolute;
        bottom: calc(2rem + 30px);
    }
    .button {
        align-self: center !important;
        background-color: $naggiar-yellow !important;
        color: black !important;
        margin-bottom: 2rem !important;
        position: absolute;
        bottom: 0;
    }
}

.adminWrap {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 275px;
    background-color: #e8e8e8;
    border-radius: 15px;
    position: relative;

    p:first-child {
        img {
            height: 125px;
            max-width: 90%;
            margin: 1rem 0;
            border-radius: 10px;
            object-fit: none;
        }
    }

    img {
        object-fit: contain;
        max-width: 90%;
    }

    h1 {
        margin-bottom: 2rem;
        margin-left: $inner-card-spacing;
        margin-right: $inner-card-spacing;
        font-family: $nangec-font !important;
    }

    p {
        margin: 0 $inner-card-spacing;
        margin-bottom: 1rem;
        font-size: 14px;
        max-width: 90%;
        word-break: break-word;
            white-space: normal;
    }

    span {
        p {
            margin: 0 $inner-card-spacing;
            margin-bottom: 1rem;
            font-size: 14px;
            max-width: 90%;
        }
    }
    
    .adminBtn {
        align-self: center !important;
        background-color: $naggiar-yellow !important;
        color: black !important;
        margin-bottom: 2rem !important;
    }
}