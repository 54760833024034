@import '../../../styles/global.scss';

.wrapper {
    margin: 0 $pages-outer-margin;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    div {
        text-align: center;
        height: 100px;
    }

    .whoops {
        font-size: 50px;
        font-weight: bold;
        font-style: italic;
    }

    .nothing {
        font-size: 25px;
    }

    .humor {
        font-size: 30px;
        font-weight: bold;
    }
}