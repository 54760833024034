@import '../../../styles/global.scss';
.wrapper {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    .title {
        margin-bottom: 4rem;
    }

    .form {
        width: 369px;
        margin-bottom: 2rem;
        @media screen and (max-width: $breakpoint-mobile + 1) {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            width: 300px;
        }
        .field {
            @media screen and (max-width: $breakpoint-mobile + 1) {
                width: 100%;
            }
        }
    }
}