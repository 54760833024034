@import '../../../../../styles/global.scss';

.burgerWrap {
    position: fixed;
    right: 4rem;
    margin-top: 2rem;
    z-index: 999;

    div {
        cursor: pointer;
    }
}

.btnWrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100%;
    z-index: 10;
    width: 18rem;
    right: 0;
    background-color: #464646;
    transition: all 0.5s;

    @media screen and (max-width: $breakpoint-tablet) {
        flex-wrap: wrap;
    }

    button {
        width: 100% !important;
        border-radius: 0 !important;
        color: white !important;
        border: unset !important;
        transition: all 0.2s !important;
        border: 1px solid transparent !important;

        &:hover {
            border: 1px solid white !important;
            color: white !important;
        }
    }
}