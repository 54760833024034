@import '../../../styles/global.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media screen and (min-width: $breakpoint-laptop) {
        width: 50vw;
        margin: $pages-outer-margin auto;
    }

    @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-laptop - 1) {
        width: 60vw;
        margin: 3rem auto;
    }

    @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop - 1) {
        width: 70vw;
        margin: 1rem auto;

    }

    @media screen and (max-width: $breakpoint-tablet - 1) {
        width: 90vw;
        margin: 0 auto;
    }

    .topEditors {
        display: flex;
    }

    .topSection {

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 6rem;
        margin-bottom: 4rem;

        h1 {
            font-size: 50px;
            font-family: $nangec-font !important;
        }

        h2 {
            font-size: 30px;
            @media screen and (max-width: $breakpoint-large-mobile) {
                margin-top: 10px;
            }
        }
        @media screen and (max-width: $breakpoint-tablet) {
            grid-gap: 0rem;
        }

        img {
            object-fit: contain;
            max-width: 100%;
        }

        div:nth-child(2) {

            h1, h2, h3, h4, h5, h6, p {
                text-align: left;
            }
        }
    }
    
    .btmHtmlSection {
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 2rem;
        width: 100%;
        margin-top: 4rem;


        
        h2 {
            font-size: 30px;
            margin-bottom: 0
        }

        p {
            font-size: 18px;
            word-break: break-word;
            white-space: normal;

            span {
                font-size: 18px;
                word-break: break-word;
                white-space: normal;
            }

            img {
                max-width: 100%;
                object-fit: contain;
            }
        }
    }

    .btmSection {
        margin-top: 6rem;
        text-align: center;
    }
}