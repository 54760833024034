
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 4rem;
    font-family: 'Roboto', sans-serif !important;
    font-size: 17px;
    scroll-margin: 50px;
    
    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;
    }


    hr {
        border-style: solid;
        width: 200%;
        margin-left: -5rem;

    }
    img {
        width: 13rem;
        cursor: pointer;
    }

    .burger {
        font-size: 17px;
        cursor: pointer;

        span {
            height: 2px;
        }
    }
}
