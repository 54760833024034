@import '../../styles/global.scss';
.wrapper {
    border-image: url(https://www.naggiar.net/sites/all/themes/naggiar/images/border.png);
    border-image-slice: 100% 0 0 0;
    border-image-repeat: repeat;
    border-image-width: 1;
    border-style: solid;
    margin-top: 40px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 3rem;
    font-size: 12px;
    

    .smLinksWrapper {
        margin-top: 8rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 24px;
            margin: 0 9px;
        }
    }

    .normalLinks {
        margin: 1rem auto;
        a {
            margin: 0 4px;
            color: #e4ad16;
            transition: color 0.2s;
        }

        a:hover {
            color: grey;
        }
    }

    

    // .main, .locationsWrapper, .navContainer {
    //     margin-top: 25px;
    //     flex-basis: 33%;
    //     .inner {
    //         width: 50%;

    //         .email {
    //             cursor: pointer;
    //             color: #0092ff
    //         }

    //         .location {
    //             font-weight: bold;
    //             margin-bottom: 14px;
    //         }
    //     }

    //     .nahr, .ghazieh, .zahleh {
    //         margin-bottom: 2.5rem;
    //     }
    // }

    // .navContainer {
    //     @media screen and (max-width: $breakpoint-small-laptop) {
    //         grid-column: 1 / span 2;
    //     }
    //     .linksContainer {
    //         display: flex;
    //         justify-content: space-evenly;
    //         width: 75%;
    //         @media screen and (max-width: $breakpoint-small-laptop) {
    //             width: 100%;
    //         }

    //         li {
    //             display: block;

    //             a {
    //                 text-decoration: none;
    //                 font-family: 'Roboto', sans-serif;
    //                 font-size: 14px;
    //                 font-weight: 300;
    //                 color: black;
    //             }
    //         }
    //     }

    //     .ctaContainer {
    //         display: flex;
    //         justify-content: space-between;
    //         margin-top: 3rem;
    //         margin-right: 2rem;
    //         @media screen and (max-width: $breakpoint-small-laptop) {
    //             margin-right: 0;
    //         }
    //         div {
    //             height: 20px;
    //             width: 140px;
    //             line-height: 10px;
    //             display: inline-flex;

    //             img {
    //                 margin-top: 0.5px;
    //                 cursor: pointer;
    //             }

    //             p {
    //                 display: inline-block;
    //                 margin-left: 2px;
    //             }
    //             a {
    //                 display: inherit;
    //                 text-decoration: none;
    //                 color: black;
    //             }
    //         }
    //     }
    //}
}





