@import '../../../../styles/global.scss';

.wrapper {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .form {
        width: 369px;
        margin: 0 auto;
        @media screen and (max-width: $breakpoint-mobile + 1) {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            width: 300px;
        }

        h1 {
            text-align: unset;
            margin-top: 3rem;
            margin-bottom: 1rem;
            font-family: $nangec-font !important;
        }
        .field {
            @media screen and (max-width: $breakpoint-mobile + 1) {
                width: 100%;
            }
        }
    }

    .user {
        width: 369px;
        margin-top: 1rem;
        font-size: 30px;
        font-family: "Merriweather", sans-serif;
    }
}
