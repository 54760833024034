.wrapper {
    margin: 1rem 0;
    display: flex;
    align-items: flex-start;

    .btnWrapper {
        cursor: default !important;
        .fileBtn {
            background-color: #FFBB00 !important;
            color: white !important;
        }
    }

    .viewFileBtn {
        color: #d6d6d6;
        background-color: #d6d6d6;

        &:hover {
            cursor: default;
            color: #d6d6d6 !important;
            background-color: #d6d6d6 !important;
        }
    }
}

.fileDisplayWrapper {
    margin: 1rem 0;
    display: flex;
    align-items: flex-start;

    .btnWrapper {
        cursor: default !important;
        .fileBtn {
            background-color: #FFBB00 !important;
            color: white !important;
        }
    }

    .viewFileBtn {
        color: #d6d6d6;
        background-color: #d6d6d6;

        &:hover {
            cursor: default;
            color: #d6d6d6 !important;
            background-color: #d6d6d6 !important;
        }
    }
}