@import '../../../../../styles/global.scss';
.ulWrapper {
    
    @media screen and (max-width: $breakpoint-small-laptop + 1) {
        font-size: 12px;
    }
    .first {
        display: flex;
        align-items: center;
        height: 25px;
    
        .save {
            margin-left: 0.5rem;
            font-style: italic;
            color: green;
        }
    }
    
    .other {
        height: 20px;
    
        .save {
            margin-left: 0.5rem;
            font-style: italic;
            color: green;
        }
    }
    
    .title {
        margin-bottom: 1rem;
        @media screen and (max-width: $breakpoint-small-laptop + 1) {
            font-size: 14px;
        }
    }
}