.medium {
    font-family: "Merriweather", sans-serif;
    padding: 20px 40px;
    color: white;
    background-color: black;
    border: none;
    border-radius: 3px;
    align-self: flex-start;
    cursor: pointer;
}

.small {
    font-family: "Merriweather", sans-serif;
    padding: 13px 26px;
    color: white;
    background-color: black;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.large {
    font-family: "Merriweather", sans-serif;
    padding: 30px 60px;
    color: white;
    background-color: black;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

