.wrapper {
    margin-top: 4rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    

    .infoWrapper {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70%;

        .file {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: 25px;
                margin-right: 5px;
            }

            .remove {
                cursor: pointer;
                margin-left: 8px;
            }
        }

        .done {
            margin-top: 3rem;
        }

        .resultsBtn {
            margin: 1rem 0;
        }

        progress {
            width: 90%;
        }

        .niceProg {
            width: 90%;
        }
    }
}