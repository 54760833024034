
// top-level pages
$pages-outer-margin: 4rem;

//materials-card
$inner-card-spacing: 13px;

$naggiar-yellow:#FFBB00;


//breakpoints
$breakpoint-large-computer: 1440px;
$breakpoint-laptop: 1280px;
$breakpoint-small-laptop: 1024px;
$breakpoint-tablet: 768px;
$breakpoint-large-mobile: 425px;
$breakpoint-mobile: 375px;
$breakpoint-mobile-small: 320px;

// fonts
@font-face {
    font-family: 'NANG___';
    src: local('NANG___'), url(./../fonts/NANG____.ttf) format('truetype');
}

@font-face {
    font-family: 'NANGB___';
    src: local('NANGB___'), url(./../fonts/NANGB___.ttf) format('truetype');
}

@font-face {
    font-family: 'NANGEC__';
    src: local('NANGEC__'), url(./../fonts/NANGEC__.ttf) format('truetype');
}
$nangec-font: 'NANGEC__', sans-serif;