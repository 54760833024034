@import '../../../styles/global.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20vw;

    @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop) {
        margin: 0 20vw;
    }

    @media screen and (min-width: $breakpoint-large-mobile) and (max-width: $breakpoint-tablet) {
        margin: 0 14vw;
    }

    @media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-large-mobile) {
        margin: 0 10vw;
    }

    .innerWrapper {
        @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-large-computer) {
            width: 700px;
        }
    
        @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop) {
            width: 500px;
        }
    
        @media screen and (min-width: $breakpoint-large-mobile) and (max-width: $breakpoint-tablet) {
            width: 400px;
        }
    
        @media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-large-mobile) {
            width: 300px;
        }
    
        .mainTitle {
            font-size: 65px;
            font-family: 'Roboto', sans-serif !important;


            @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop) {
                font-size: 52px;
            }
        
            @media screen and (max-width: $breakpoint-tablet) {
                font-size: 45px;
            }
        }

        hr {
            width: 100%;
            color: rgb(96, 96, 96);
        }
        
        h1 {
            font-weight: 200;
            margin-bottom: 3rem;
            font-family: $nangec-font !important;
            font-size: 60px;
            @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-large-computer) {
                font-size: 54px;
            }
        
            @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop) {
                font-size: 44px;
            }
        
            @media screen and (min-width: $breakpoint-large-mobile) and (max-width: $breakpoint-tablet) {
                font-size: 39px;
            }
        
            @media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-large-mobile) {
                font-size: 32px;
            }
        }

        h2 {
            font-size: 30px;
            margin-bottom: 3rem;
            font-family: 'Roboto', sans-serif !important;

            @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-large-computer) {
                font-size: 40px;
            }
        
            @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop) {
                font-size: 30px;
            }
        
            @media screen and (min-width: $breakpoint-large-mobile) and (max-width: $breakpoint-tablet) {
                font-size: 25px;
            }
        
            @media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-large-mobile) {
                font-size: 22px;
            }
        }

        img {
            margin-bottom: 4rem;
            width: 100%;
        }
    
        p {
            margin-bottom: 3rem;
            
            @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-large-computer) {
                font-size: 20px;
            }
        
            @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop) {
                font-size: 17px;
            }
        
            @media screen and (min-width: $breakpoint-large-mobile) and (max-width: $breakpoint-tablet) {
                font-size: 14.5px;
            }
        
            @media screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-large-mobile) {
                font-size: 14px;
            }
    
            span {
                cursor: pointer;
                text-decoration: underline;
                font-weight: bold;
                color: navy;
            }

            a {
                cursor: pointer;
                text-decoration: underline;
                font-weight: bold;
                color: navy;
            }
        }
    }
    
}

