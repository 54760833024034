@import '../../../styles/global.scss';

.wrapper {
    margin: 4rem 2rem;
    display: flex;
    flex-direction: column;
    overflow-x: scroll;

    @media screen and (max-width: $breakpoint-tablet) {
        font-size: 12px;
    }

    .total {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        margin-top: 3rem;
    }

    

    a {
        color: #eeaf00;
    }
}

.btnWrapper {
    margin: 4rem 2rem;
    display: flex;
    flex-direction: column;

    .checkoutBtn {
        align-self: center;
        margin-bottom: 1rem !important;
    }

    .resultsBtn {
        align-self: center;
        margin-bottom: 1rem !important;
    }
}