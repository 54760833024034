@import '../../../../styles/global.scss';

.wrapper {
    display: flex;
    align-items: center;
    background-color: lightgrey;
    height: 60px;
    cursor: pointer;

    .colorPrev {
        height: 40px;
        width: 40px;
        margin-left: 1rem;
    }

    .text {
        margin-left: 3rem;

        @media screen and (max-width: $breakpoint-large-mobile) {
            margin-left: 3rem; 
            margin-right: 1rem;
        }
    }
}

