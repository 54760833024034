@import '../../../../../styles/global.scss';

.thicknessChoices {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    width: 50%;

    @media screen and (max-width: $breakpoint-tablet) {
        width: 100%;
        height: 60%;
        overflow-y: scroll;
    }

    @media screen and (max-width: $breakpoint-large-mobile) {
        grid-template-columns: 1fr;
    }

    @media screen and (max-width: $breakpoint-mobile) {
        grid-template-columns: 1fr 1fr;
    }
}