@import '../../../styles/global.scss';
.wrapper {
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $breakpoint-large-mobile) {
        width: 100%;
        height: unset;
    }

    
    
    ::-webkit-scrollbar {
        display: unset;
    }

    .choicesWrapper {
        overflow-y: scroll;
        display: flex;
        @media screen and (max-width: $breakpoint-tablet) {
            flex-direction: column;   
            overflow-y: unset;         
            height: 56%;
        }
        
    }


    .metalTypesWrapper, .thicknessChoicesWrapper, .quantityChoicesWrapper {
        @media screen 
        and (min-device-width: 1200px) 
        and (max-device-width: 1600px) 
        and (-webkit-min-device-pixel-ratio: 2)
        and (min-resolution: 192dpi) { 
            // height: 29%;
        }
    }
}

.btnSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: white;

    .navBtns {
        @media screen and (max-width: $breakpoint-large-mobile) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
        }
    }

    .addToCart {
        margin-left: auto;
        @media screen and (max-width: $breakpoint-large-mobile) {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    @media screen and (max-width: $breakpoint-large-mobile) {
        position: unset;
    }

    hr {
        flex: 0 0 100%;
    }
}

.directional {
    height: 14rem;
    width: 100%;
    background-color: #28a745;
    border-left: 8px solid #1f8838;

    .top {
        width: 100%;
        align-items: center;
        margin-top: 1rem;
        
        .contentWrap {
            margin-left: 1rem;

            .success {
                margin-top: 2rem;
                color: white;
                font-size: 18px;
                font-weight: bold;
            }

            .redirect {
                margin-top: 2rem;
                color: white;
                font-size: 14px;

                &::after {
                    display: inline-block;
                    animation: ellipsis 1.25s infinite;
                    content: ".";
                    width: 1em;
                    text-align: left;
                }
            }

            @keyframes ellipsis {
                0% {
                    content: ".";
                }
                33% {
                    content: "..";
                }
                66% {
                    content: "...";
                }
            }
        }
        
    }
    .bottom {
        margin-top: 2.5rem;
        display: flex;
        justify-content: space-evenly;
    }
}