@import '../../../styles/global.scss';
.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 4rem;
    margin-left: $pages-outer-margin;
    margin-right: $pages-outer-margin;


    @media screen and (max-width: $breakpoint-small-laptop) {
        grid-template-columns: 1fr;
    }
    

    .firstColumn {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media screen and (max-width: $breakpoint-small-laptop) {
            align-items: center;
            width: unset;
        }

        .text {
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            
            @media screen and (max-width: $breakpoint-small-laptop) {
                display: unset;
                text-align: center;
            }

            @media screen and (min-width: $breakpoint-large-computer) {
                height:500px;
                width: 750px;
            }

            @media screen and (min-width: $breakpoint-small-laptop) {
                height: 60vh;
            }
            


            .heading {
                @media screen and (min-width: $breakpoint-large-computer) {
                    font-size: 80px;
                }
                @media screen and (min-width: $breakpoint-laptop) and (max-width: $breakpoint-large-computer - 1) {
                    font-size: 60px;
                }
                @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-laptop - 1) {
                    font-size: 45px;
                }
                font-size: 65px;
                margin-bottom: 1rem;
                font-family: 'Roboto', sans-serif !important;
            }

            .subheading {
                @media screen and (min-width: $breakpoint-large-computer) {
                    font-size: 45px;
                }
                @media screen and (min-width: $breakpoint-laptop) and (max-width: $breakpoint-large-computer - 1) {
                    font-size: 30px;
                }
                @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-laptop - 1) {
                    font-size: 25px;
                }
                @media screen and (max-width: $breakpoint-small-laptop) {
                    margin: 2rem 0;
                }
                margin-bottom: 1.5rem;
                margin-top: 0;
                font-size: 30px;
                font-family: 'Roboto', sans-serif !important;
            }
        }

        .smallCta {
            margin-bottom: 2rem;
            margin-top: 1rem;
            font-size: 16px;
            @media screen and (max-width: $breakpoint-large-mobile) {
                text-align: center;
            }
        }
    }

    .image {
        @media screen and (max-width: $breakpoint-small-laptop) {
            text-align: center;
        }
        img {
            float: right;
            max-height: 90%;
            max-width: 90%;
            @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-laptop - 1) {
                float: unset;
                max-width: 100%
            }
            @media screen and (max-width: $breakpoint-small-laptop) {
                float: unset;
            }
        }
    }
}