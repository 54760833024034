@import '../../../../styles/global.scss';

.wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    
    @media screen and (max-width: $breakpoint-large-mobile) {
        font-size: 15px;
    }

    .titleWrapper {
        flex-basis: 100%;
        margin-bottom: 1rem;

        @media screen and (max-width: $breakpoint-large-mobile) {
            margin-bottom: 0;
        }

        .title {
            font-family: 'Roboto', sans-serif !important;
            font-size: 36px;
        }
    }

    .detailsCol {
        display: flex;
        justify-content: space-evenly;
        flex-basis: 100%;
        align-items: center;

        @media screen and (max-width: $breakpoint-large-mobile) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .fileInfo {
            height: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: $breakpoint-large-mobile) {
                flex-direction: column;
            }
            
            p {
                padding-left: 8px;
                font-size: 24px;

                @media screen and (max-width: $breakpoint-large-mobile) {
                    padding-left: 0;
                    margin-bottom: 1rem;
                    font-weight: bold;
                }
            }
            .icon {
                height: 100%;
            }

            .blob {
                height: 170px;
            }
        }

        .verificationDisplay {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;


            .unitsInput {
                display: flex;
                align-items: center;
                margin-bottom: 1rem;
            }

            .lenAndWidth {
                display: flex;
                align-items: center;
                @media screen and (max-width: $breakpoint-large-mobile + 1) {
                    flex-direction: column;
                }
            }

            span {
                margin-left: 2px;
            }
        }

        .dimInput {
            font-size: 12px;

            input {
                width: 100px;
            }
        }
    }
}

.metal {
    font-weight: bold;
    font-size: 19px;
    font-style: italic;
    color: $naggiar-yellow;

    @media screen and (max-width: $breakpoint-large-mobile) {
        margin-top: 1rem;
        text-align: center;
    }
}