@import '../../../../styles/global.scss';

.wrapper {
    border: 3px solid rgb(192, 192, 192);
    border-radius: 2rem;
    margin: 2rem;

    
    .directions {
        padding: 1rem;
        @media screen and (max-width: $breakpoint-small-laptop) {
            padding: 1.5rem;
        }
    }
    .inner {
        padding: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        button {
            margin-top: 1rem !important;
        }

        .name, .formula {
            width: 400px;
            margin-top: 1rem;
            
            @media screen and (max-width: $breakpoint-large-mobile) {
                width: 290px;
            }
        }
    }
    
}