@import '../../styles/global.scss';
.wrapper {
    margin: 3rem 4rem;
    @media screen and (max-width: $breakpoint-large-mobile + 1) {
        margin: 3rem 1.5rem;
    }
}

.btnWrapper {
    height: 3rem;
}

.hide {
    overflow:hidden;
}

.yesButton {
    padding: 15px 40px;
    color: white;
    background-color: black;
    border: none;
    border-radius: 3px;
    float: right;

    &:hover {
        background-color: grey;
        border: black;
    }
}

.goBackButton {
    padding: 20px 40px;
    color: white;
    background-color: black;
    border: none;
    border-radius: 3px;
    float: left;

    &:hover {
        background-color: grey;
        border: black;
    }
}