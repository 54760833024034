@import '../../../styles/global.scss';

.slideStyles {
    overflow: hidden;
    width: 50rem;
    height: 75vh;
}

.innerSlideClass {
    height: 72vh;
    margin-top: 1rem;
    margin-left: 1rem;
    overflow: scroll;
}

.customModal {
    height: 85vh;
    width: 90vw;
    @media screen and (max-width: $breakpoint-large-mobile) {
        overflow: scroll;
    }
    
}
