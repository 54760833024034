@import '../../../../../styles/global.scss';

.quantityChoicesWrapper {
    width: 50%;
    @media screen and (max-width: $breakpoint-tablet) {
        width: 100%;
        height: 40%;
        overflow-y: scroll;
    }
    
    .choices {
        width: 100%;
        padding-left: 7%;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: $breakpoint-tablet) {
            padding: 7px 0 0 0;
            justify-content: center;
            overflow-y: scroll;
        }

        @media screen and (max-width: $breakpoint-mobile) {
            grid-template-columns: 1fr 1fr;
        }
    }
}
