@import '../../../../styles/global.scss';

.ulWrapper {
    width: 9rem;
    @media screen and (max-width: $breakpoint-large-mobile) {
        width: 7rem;
    }
    
    input {
        cursor: pointer;
    }

    .title {
        margin-bottom: 1rem;
    }
    
    .inputWrapper {
        display: flex;
        align-items: center;
        width: 60%;
    
        .iconWrapper {
            margin-left: 3px;
            
            @media screen and (max-width: $breakpoint-large-mobile) {
                width: 70%;
            }

            // span {
            //     margin-left: 6px;
            //     @media screen and (max-width: $breakpoint-large-mobile) {
            //         margin-left: 7px;
            //     }
            // }
            
            .customQtyInput {
                width: 3.2rem;
                padding-left: 6px;
                font-family: 'Merriweather', sans-serif;
                border: 2px solid $naggiar-yellow;
                border-radius: 2px; 
            }
    
            .arrowSection {
                position: absolute;
                bottom: 4px;
                height: 22px;
                left: 65%;

                @media screen and (max-width: $breakpoint-mobile + 1) {
                    height: 21px;
                    left: 60%;
                }
    
                .icon {
                    height: 11px;
                    cursor: pointer;
                }
            }
        }
    }
}

.number {
    margin-left: 9px;
}