@import '../../../../styles/global.scss';

.wrapper {
    width: 400px;

    @media screen and (max-width: $breakpoint-large-mobile) {
        width: 300px;
    }

    .labelMsg {
        margin-top: 1rem !important;
    } 

    .fileBtn {
        background-color: #FFBB00 !important;
        color: white !important;
        cursor: pointer;
    }

    .qtyFields {
        justify-content: space-between;

        .fieldWrapper {
            display: flex;
            flex-direction: column;
        }

        .customQtyInput {
            width: 5rem !important;
        }
    }

    .immutable {
        justify-content: space-between;
        padding-bottom: 2rem;
    }
}