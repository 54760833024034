@import '../../styles/global.scss';

.wrapper {
    width: 500px;
    max-width: 97vw;
    margin: 1rem auto;
    text-align: left;

    .miniGallery {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr ;
        row-gap: 1rem;
        background-color: #dadada;
        border-radius: 5px;
        padding-top: 15px;

        @media screen and (max-width: 550px) {
            grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: $breakpoint-large-mobile) {
            grid-template-columns: 1fr;
        }


        input {
            max-width: 125px;
            max-height: 125px;
            cursor: pointer;
        }
        

        .imgOuterWrap {
            position: relative;
            display: inline-block;
            justify-self: center;

            .iconWrap {
                position: absolute;
                top: -10px;
                right: -12px;
                width: 19px;
                border-radius: 50%;
                background: white;

                .icon {
                    height: 100%;
                    width: 100%;
                    margin: 0;
                    font-size: 12px;
                }
            }
        }
    }
    
}


