@import '../../../styles/global.scss';
.topSection {
    margin-top: 4rem;
    margin-bottom: 1rem;
    color: rgb(39, 39, 39);
    font-weight: bold;
    text-align: center;

    .welcome {
        font-size: 3rem;
        margin-bottom: 2rem;
        font-family: 'Roboto', sans-serif !important;
        @media screen and (max-width: $breakpoint-large-mobile + 1) {
            font-size: 2rem;
        }
    }
    .cta {
        font-size: 20px;
        font-family: 'Roboto', sans-serif !important;
        @media screen and (max-width: $breakpoint-large-mobile + 1) {
            font-size: 16px;
        }
    }
    .clickAnywhere {
        margin-top: 2rem;
        font-style: italic;
        @media screen and (max-width: $breakpoint-large-mobile + 1) {
            font-size: 14px;
        }
    }
}

.btmSection {
    text-align: center;
}

.fileTypes {
    margin-top: 0;
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    @media screen and (max-width: $breakpoint-large-mobile + 1) {
        font-size: 12px;
    }
}