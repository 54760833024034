@import '../../../../styles/global.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    align-items:center;
    margin: $pages-outer-margin auto;
    width: 60vw;

    @media screen and (min-width: $breakpoint-laptop) {
        width: 60vw;
    }

    @media screen and (min-width: $breakpoint-small-laptop) and (max-width: $breakpoint-laptop - 1) {
        width: 70vw;
    }

    @media screen and (min-width: $breakpoint-tablet) and (max-width: $breakpoint-small-laptop - 1) {
        width: 80vw;
    }

    @media screen and (max-width: $breakpoint-tablet - 1) {
        width: 90vw;
    }


    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        justify-items: center;
    }

    h1, h2 {
        text-align: center;
    }
    h1 {
        font-size: 34px;
        font-weight: bold;
        margin-bottom: 2rem;
        font-family: $nangec-font !important;
    }

    h2 {
        font-size: 25px;
        font-family: 'Roboto', sans-serif !important;
    }

    p {
        width: 100%;
        margin-bottom: 3rem;
        word-break: break-word;
        white-space: normal;
        text-align: center;
        font-size: 18px;

        img {
            max-width: 100%;
            object-fit: contain;
        }
    }


    .bottomWrapper {
        font-size: 18px;
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;

        em {
            display: inline-block;
        }

        p {
            margin: 0;
        }

        br {
            flex-basis: 100%;
            content: '';
        }

    }

    .btn {
        margin: 3rem 0 !important;
    }
}