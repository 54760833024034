@import '../../../../../styles/global.scss';

.title {
    font-family: 'Roboto', sans-serif !important;
    font-size: 36px;
}

.wrapper {
    width: 80vw;

    @media screen and (max-width: $breakpoint-large-mobile) {
        width: 90vw;
    }
    .formWrapper {
        width: 400px;
        @media screen and (max-width: $breakpoint-large-mobile) {
            width: 300px;
        }
    }
}

.finalPrice {
    text-align: right;
    margin-bottom: 2rem;
    font-weight: bold;
    font-size: 17px !important;
}

@keyframes fadein {
    0% { opacity: 0; }
    100%   { opacity: 1; }
}

.thankyou {
    height: 2.5rem;
    align-items: center;
    display: flex;
    margin-top: 1rem;
    animation: fadein 2s;
}

.tableContainer {
    width: 100%;
    overflow-x: scroll;
    .tbl {
        @media screen and (max-width: $breakpoint-small-laptop) {
            font-size: 12px !important;
        }
        @media screen and (max-width: $breakpoint-tablet) {
            font-size: 10px !important;
            width: 80vw !important;
        }
    }
}
