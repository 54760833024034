@import '../../../styles/global.scss';

.wrapper {
    position: relative;
    transform: translateZ(0);
    width: 100%;
    height: 1000px;


    .mainSection {
        margin-right: 18rem;
        h1 {
            margin: 0;
            padding: 2rem 0;
            font-family: $nangec-font !important;
            text-align: center;
        }
    }
}