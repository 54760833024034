.wrapper {
    border: 3px solid rgb(192, 192, 192);
    border-radius: 2rem;
    margin: 2rem;
    overflow-x: auto;

    .inner {
        padding: 1rem;
        width: 100%;

        .top {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .titleArea {
                display: flex;
                justify-content: space-between;
            }

            .thumbnailWrap {
                margin: 1.5rem 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                span {
                    font-size: 15px;
                }

                .btnWrapper {
                    cursor: default !important;
                    .fileBtn {
                        background-color: #FFBB00 !important;
                        color: white !important;
                    }
                }

                .viewFileBtn {
                    color: #d6d6d6;
                    background-color: #d6d6d6;

                    &:hover {
                        cursor: default;
                        color: #d6d6d6 !important;
                        background-color: #d6d6d6 !important;
                    }
                }
            }
        }
    }
}