@import '../../../styles/global.scss';
.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;

    @media screen and (max-width: $breakpoint-tablet + 1) {
        grid-template-columns: 1fr;
    }

    .title {
        margin: 0;
    }

    .subtitle {
        font-size: 16px;
        margin-bottom: 2rem;
        margin-top: 0;
        font-weight: normal;
    }

    .form {
        width: 369px;
        margin-bottom: 2rem;
        @media screen and (max-width: $breakpoint-mobile + 1) {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            width: 300px;
        }
        .field {
            @media screen and (max-width: $breakpoint-mobile + 1) {
                width: 100%;
            }
        }
    }
}
