@import '../../../../../styles/global.scss';

.metalTypesWrapper {
    overflow-y: scroll;
    margin-top: 6px;
    margin-bottom: 6px;

    .choices {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;

        @media screen and (max-width: $breakpoint-large-mobile) {
            grid-template-columns: 1fr;
        }
    }
}