.wrap {
    border: 8px solid #FFBB00;

    @media screen and (max-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    span {
        margin-right: 5px;

        @media screen and (max-width: 1024px) {
            margin-right: 2rem;
        }
    }
    
    
}