.wrapper {
    margin: 2rem;
    .inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 2rem;
    }
}

