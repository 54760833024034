.links {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    @media screen and (max-width: 1024px) {
        width: 100%;
        flex-direction: column;
        grid-gap: 1rem;
    }

    a {
        font-size: 17px;
        font-family: 'Roboto', sans-serif !important;
        color: black;

        &:hover {
            color: #FFBB00;
            cursor: pointer;
        }
    }

    .cartGroup {
        display: flex;
        position: relative;
        align-items: center;
        cursor: pointer;

        &:hover > * {
            color: #FFBB00;
        }

        .qty {
            position: absolute;
            color: white;
            left: 18px;
            bottom: 9px;
            font-size: 10.5px;
            font-weight: bold;
            font-family: sans-serif;
        }
    }
}